.add-image .canvas-container {
    border: 1px solid #cad8fc;
}

.add-image .coupon {
    width: 640px;
    height: 400px;
    margin-bottom: 10px;
}

.add-image .postcard {
    width: 1016px;
    height: 1647px;
    margin-bottom: 10px;
}

.add-image .load button{
    position: relative;
    width: 100%;
    z-index: 1;
    height: 100%;
    background-color: white;
    border-radius: 4px;
    border-color: lightblue;
}

.back-button:hover {
    box-shadow: 5px rgb(20,20,20);
}