.modal__background {
    position: fixed;
    background: rgba(13, 83, 252, 0.2);
    backdrop-filter: blur(8px);
    height: 100%;
    width: 100%;
    z-index: 999;
    top: 0;
    left: 0;
  }
  .modal-dialog{
    padding: 10px;
    position: relative;
    overflow-y: scroll;
    width: 100%;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
  }
  
  .modal {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    background-color: #fff;
    color: #2B2E32;
  
    border-radius: 24px;
    box-shadow: 0px 32px 80px 16px rgba(47, 70, 123, 0.16);
  
    height: fit-content;
  
    padding: 40px 32px 20px;
  
    font-size: 14px;
  }
  
  @media (min-width: 901px) {
    .modal {
      width: fit-content;
      padding: 10%;
      height: 70%;
  }
  }
  
  @media (max-width: 900px) {
    .modal {
        width: 90%;
        max-width: 400px;
    }
  }
  
  .modal__header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .modal__icon-close {
    position: absolute;
    right: 3%;
    top: 3%;
  }
  
  .modal__title {
    font-size: 32px;
    font-weight: bold;
  
    margin: 0 0 10px;
  }
  
  .modal__secondary-title {
    margin-bottom: 5px;
  
    font-size: 1.2em;
    font-weight: bold;
  }
  
  .modal__textarea {
    width: 100%;
    min-height: 100px;
  
    border-radius: 8px;
    border: 1px solid #D5DFEC;
    outline: none;
  
    margin-bottom: 20px;
    padding: 15px;
  }
  
  .modal__icon-button {
    margin-left: 15px;
    transition: all 0.3s;
  }
  
  .modal__icon-button:hover {
    transform: scale(1.1);
  }
  
  .modal__two-fields {
    display: flex;
    justify-content: space-between;
  }
  
  .modal__two-fields .modal__field:first-child {
    margin-right: 10px;
  }
  
  .modal__field {
    display: flex;
    flex-direction: column;
  
    width: 100%;
  }
  
  .modal__input {
    height: 33px;
    width: 100%;
  
    border: 1px solid #D5DFEC;
    outline: none;
    border-radius: 8px;
  
    padding: 5px 15px;
    margin-bottom: 5px;
  }
  
  .modal__input::placeholder {
    color: #D5DFEC;
  }
  
  .modal__select {
    height: 33px;
    width: 100%;
  
    border: 1px solid #D5DFEC;
    outline: none;
    border-radius: 8px;
  
    padding: 5px 15px;
  
    cursor: pointer;
  }
  
  .modal-big{
    width: 750px;
  }
  
  .modal-fullscreen{
    width: 90%;
  }
  
  @media (max-width: 900px) {
    .modal__two-fields {
        flex-direction: column;
        align-items: stretch;
    }
  
    .modal__two-fields .modal__field:first-child {
      margin-right: 0;
    }
  
    .modal__two-fields .modal__autocomplete:first-child {
      margin-bottom: 10px;
    }
  }
  
  .modal__autocomplete {
    margin-bottom: 10px;
  }
  
  .modal__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    margin: 20px 0;
  }
  
  .modal__icon-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  
    margin: 20px 0;
  }
  
  @media (max-width: 900px) {
    .modal__buttons {
      flex-direction: column;
      align-items: center;
    }
  }
  
  .modal__button {
    border-radius: 8px;
  
    background-color: #0D53FC;
    color: #fff;
  
    height: 33px;
    width: 150px;
  
    padding: 5px 15px;
    margin-right: 10px;
  
    transition: 0.3s;
  }
  
  @media (max-width: 900px) {
    .modal__button {
        width: 100%;
        margin-bottom: 10px;
        margin-right: 0;
    }
  }
  
  .modal__button:hover {
    background-color: #0042E2;
  }
  
  .modal__link {
    padding: 15px 0 2px;
  
    width: fit-content;
  
    color: #0D53FC;
    border-bottom: 1px solid transparent;
  
    transition: 0.3s;
  }
  
  .modal__link:hover {
    color: #0042E2;
    border-bottom: 1px solid #0042E2;
  }
  
  .modal__content-block {
    margin-bottom: 15px;
  }
  
  .modal__colored-tag {
    padding: 5px 8px;
  
    border-radius: 4px;
  
    font-weight: bold;
    font-size: 0.8rem;
  
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .modal__colored-tag span {
    display: block;
  
    height: 5px;
    width: 5px;
  
    border-radius: 50%;
  
    margin-right: 8px;
  }
  
  .modal__colored-tag--red {
    color: #EE3D72;
    background-color: #ffbbcf;
  }
  
  .modal__colored-tag--red span {
    background-color: #EE3D72;
  }
  
  .modal__colored-tag--blue {
    color: #0D53FC;
    background-color: #aac7ec;
  }
  
  .modal__colored-tag--blue span {
    background-color: #0D53FC;
  }
  
  .modal__colored-tag--green {
    color: #2db82d;
    background-color: #adffb8;
  }
  
  .modal__colored-tag--green span {
    background-color: #2db82d;
  }
  
  .modal__toggler {
    display: none;
  }
  .modal__toggler:checked +.modal__toggler-label {
    background: rgba(13, 83, 252, 0.2);
    border: 1px solid #0D53FC;
    color: #0D53FC;
    font-weight: bold;
  }
  
  .modal__toggler-label {
    width: 100%;
    padding: 8px 10px;
    border-radius: 8px;
    border: 1px solid #D5DFEC;
  
    cursor: pointer;
  
    text-align: center;
  
    transition: all 0.3s;
  }
  
  .modal__toggler-label:hover {
    color: #0D53FC;
  }
  
  .modal__button-progress {
    height: 10px !important;
    width: 10px !important;
  
    color: #fff !important;
  }
  
  .modal__signature-img {
    height: 50px;
    width: 100px;
  
    border-radius: 4px;
  }
  
  @media (max-width: 900px) {
    .modal__signature-img {
      height: 100px;
      margin-bottom: 10px;
    }
  }
  
  .MuiInput-underline::before,
  .MuiInput-underline::after {
    display: none;
  }
  
  .MuiInputLabel-outlined {
    z-index: 0 !important;
  }
  /* .MuiPickersDay-day {
    border-radius: 8px !important;
  }
  
  .MuiPickersDay-daySelected {
    background-color: #0D53FC !important;
  }
  
  .modal__datepicker {
    height: 33px !important;
  
    margin-bottom: 5px !important;
  
    border: 1px solid #D5DFEC !important;
    outline: none;
    border-radius: 8px;
    box-sizing: border-box !important;
  }
  
  .modal__datepicker input{
    padding: 8px 15px;
  }
   */
  
  .modal__datepicker p {
    color: #EE3D72;
    position: relative;
    top: -60px;
    left: 150px;
  }
  
  .modal__tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    background-color: #EFEFEF;
    border-radius: 8px;
    padding: 4px 8px;
    margin-bottom: 8px;
  }
  
  .modal__tabs-item {
    padding: 8px 20px;
    width: 50%;
  
    border-radius: 8px;
  
    font-weight: bold;
    font-size: 12px;
    text-align: center;
  
    color: #5F6368;
  
    cursor: pointer;
    transition: 0.3s;
  }
  
  .modal__tabs-item:hover {
    background-color: #fff;
    opacity: 0.5;
    color: #0D53FC;
  }
  
  .modal__tabs-item--checked {
    background-color: #fff;
    color: #0D53FC;
  }